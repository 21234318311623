const Selector = {
    logo: 'img[src*="users/enterprise_logos"]'
};

export function getInterfaceData() {
    const { 'stick-nav-username': usernameElement } = window;
    const logoImage = document.querySelector<HTMLImageElement>(Selector.logo);
    const username = usernameElement?.innerHTML?.trim?.() || '';
    const enterpriseLogo = logoImage?.src || '';
    const enterpriseLogoDescription = logoImage?.alt || '';
    const interfaceData = { username, enterpriseLogo, enterpriseLogoDescription };

    return interfaceData;
}