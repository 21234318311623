const { EV_CORE_META = {} } = window;
const { hostname } = location;
const { e, c, u, i } = EV_CORE_META;
const environmentHostPrefix = {
    qaCluster: 'qa-cluster',
    devQa: 'devqa'
} as const;
const environments = {
    qaCluster: 'QA Cluster',
    devQa: 'Dev QA',
    production: 'Production'
} as const;

export function getLoggerConfig() {
    const environment = hostname.startsWith(environmentHostPrefix.devQa) ? environments.devQa
        : hostname.startsWith(environmentHostPrefix.qaCluster) ? environments.qaCluster
        : environments.production;
    const userId = `EVU_i:${i}_e:${e}_c:${c}_u:${u}`;
    const loggerConfig = {
        environments,
        environment,
        userId
    }

    return loggerConfig
}