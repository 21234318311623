import { detect as detectBrowser, BrowserInfo } from 'detect-browser';

export function getBrowserInfo() {
    const { os: browserOs, name: browserName, version: browserVersion } = detectBrowser() as BrowserInfo;
    const browserInfo = {
        browserOs: browserOs || '',
        browserName,
        browserVersion
    };

    return browserInfo
}